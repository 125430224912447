<template>
  <div class="landing_0123">
    <section>
      <div class="container">
        <nav>
          <router-link class="nav-container" to="/"
            ><img src="/svgs/logo_2.svg" alt="Logo NLC" class="logo_2 logo-nlc"
          /></router-link>
          <img
            src="/imgs/landing_0123/logo-cervantes.png"
            alt="Logo Cervantes"
            class="logo_cervantes"
          />
        </nav>
      </div>

      <div class="caption-wrapper">
        <div class="caption">
          <h1>
            <span class="text-accent">Obtenez un</span> <br />
            visa de longue durée
          </h1>

          <h4 class="mt-5">
            <span class="text-accent">
              et vivez à Madrid en étudiant l'espagnol pendant 1 an au NL
              College
            </span>
            <br />
            Accrédité par Cervantes
          </h4>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <img src="/imgs/landing_0123/01.png" alt="" />
            <div class="card">
              <div class="card-body">
                Assistant <b>Support</b> en votre <b>langue native</b>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <img src="/imgs/landing_0123/02.png" alt="" />
            <div class="card">
              <div class="card-body">
                Venez à Madrid avec un <b>visa de long séjour</b>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <img src="/imgs/landing_0123/03.png" alt="" />
            <div class="card">
              <div class="card-body">
                Commencez une <b>nouvelle vie</b> en Espagne
              </div>
            </div>
          </div>
        </div>

        <div class="img-rounded-container d-none d-lg-block mt-9">
          <img src="/imgs/landing_0123/04.jpg" alt="inclus" class="img-fluid" />
        </div>
        <div class="img-rounded-container d-none d-md-block d-lg-none mt-9">
          <img
            src="/imgs/landing_0123/04_tablet.jpg"
            alt="inclus"
            class="img-fluid"
          />
        </div>
        <div class="img-rounded-container d-block d-md-none mt-9">
          <img
            src="/imgs/landing_0123/04_mobile.jpg"
            alt="inclus"
            class="img-fluid"
          />
        </div>

        <button class="btn mt-9 mb-4" @click="goToForm">
          Je veux vivre à Madrid avec un visa de long séjour
        </button>
      </div>
    </section>

    <section>
      <img
        src="/svgs/element-08.svg"
        class="img-fluid w-100 opacity-1"
        alt=""
      />
      <div class="caption mx-auto">
        <h1>Offre limitée</h1>
        <h4 class="text-accent">jusqu'à épuisement des places disponibles</h4>
      </div>
    </section>

    <section>
      <button class="btn my-4" @click="goToForm">
        Je veux vivre à Madrid avec un visa de long séjour
      </button>

      <div class="container mt-8">
        <div class="card card-gradient py-6">
          <div class="card-body">
            <h3>
              Si vous êtes une agence, <br />
              nous sommes ouverts à toute <br />
              collaboration
            </h3>

            <h3>Cliquez sur le lien ci-dessous</h3>
          </div>
        </div>

        <button class="btn my-4" @click="goToForm">
          JE SUIS UNE AGENCE ET JE VEUX UN PARTENARIAT
        </button>
      </div>
    </section>

    <section id="form">
      <div class="container">
        <h3 class="text-accent">
          Remplissez le formulaire ci-dessous <br />
          et préparez-vous à commencer
        </h3>
        <h3>une nouvelle vie</h3>

        <div class="row justify-content-center">
          <div class="col-md-8">
            <form
              class="pt-4 px-md-7"
              id="contact-form"
              action="#contact-form"
              @submit="onSubmit"
            >
              <input
                type="text"
                placeholder="Votre nom complet"
                required
                name="your-name"
                v-model="form.name"
              />
              <input
                type="text"
                placeholder="Votre adresse e-mail"
                required
                name="your-email"
                v-model="form.email"
              />
              <input
                type="text"
                placeholder="Votre numéro de téléphone"
                required
                name="your-phone"
                v-model="form.phone"
              />

              <button type="submit" class="btn btn-sm btn-block btn-primary">
                Envoyer
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>

    <section class="footer">
      <button class="btn my-4" @click="goToForm">
        JE VEUX COMMENCER UNE NOUVELLE VIE EN ESPAGNE
      </button>
      <router-link class="nav-container" to="/">
        <img src="/svgs/logo_2.svg" alt="" class="logo_2" />
      </router-link>
    </section>
  </div>
</template>

<script>
// import { createPmlScript, removePmlScript } from "@/utils/pmlHelper.js";
import axios from "axios";

export default {
  // beforeUnmount() {
  //   removePmlScript();
  // },
  // mounted() {
  //   createPmlScript("morocco");
  // },
  data: () => ({
    form: {
      name: null,
      email: null,
      phone: null,
    },
  }),
  methods: {
    goToForm() {
      document.querySelector("#form").scrollIntoView();
    },
    onSubmit(e) {
      e.preventDefault();

      axios.get('https://api.pumpmylead.com/integrations/informs/v1/webhook', {
        params: {
          'your-phone': this.form.phone,
          'your-email': this.form.email,
          'your-name': this.form.name,
          'token': 'i0rncl2079rqxvn41195voo469426t3x2'
        }
      })

      this.clearForm();

      this.formSuccessMessage();
    },
    clearForm() {
      this.form = {
        name: null,
        email: null,
        phone: null,
      };
    },
    formSuccessMessage() {
      this.$toast.success("Merci beaucoup, votre message a été envoyé");
    },
  },
};
</script>
<style scoped>
.nav-container {
  z-index: 1000;
}
.logo-nlc {
  width: 200%;
}
</style>
